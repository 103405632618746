<template>
  <div class="wrapper activityitems">
    <ul>
      <li>
        <img src="../../../assets/imgs/newactivity/icon2.jpg" alt="">
        <div>
          <div class="tit">普通活动</div>
          <div class="smltit">适用于周期性的报名活动</div>
          <el-button class="button" type="primary" @click="gotoPage('general')">马上制作</el-button>
        </div>
      </li>
      <li>
        <img src="../../../assets/imgs/newactivity/icon1.jpg" alt="">
        <div>
          <div class="tit">场地预约</div>
          <div class="smltit">按时间段预约，限制人数</div>
          <el-button class="button" type="primary" @click="gotoPage('book')">马上制作</el-button>
        </div>
      </li>
<!--      <li>
        <img src="../../../assets/imgs/newactivity/icon3.jpg" alt="">
        <div>
          <div class="tit">公告活动</div>
          <div class="smltit">适用于通知告知类的活动</div>
          <el-button class="button" type="primary" @click="gotoPage('notice')">马上制作</el-button>
        </div>
      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{
    gotoPage(data){
      let type = null;
      if(data == 'notice'){
        type = 2
      }else if(data == 'book'){
        type = 1
      }else if(data == 'general'){
        type = 0
      }
      this.$router.push({
        path: '/activity/addactivity',
        query: {
          type: type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .activityitems{
    padding-top: 60px;
    ul{
      display: flex;
      justify-content: space-around;
      align-items: center;
      li{
        padding: 20px;
        text-align: center;
        background: #ffffff;
        cursor: pointer;
        display: flex;
        justify-content:space-between;
        align-items: center;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        border-radius: 4px;
        width: 400px;
        img{
          width: 40%;
          height: auto;
        }
        >div{
          width: 50%;
          text-align: left;
          .tit{
            font-weight: bold;
          }
          .smltit{
            margin-top: 10px;
          }
          .button{
            margin-top: 30px;
          }
        }
      }
    }
  }
</style>